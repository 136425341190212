/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Notification from './Notification/Loadable';
import NotificationForm from './NotificationForm/Loadable';
import NotificationEditForm from './NotificationEditForm/Loadable';

function index() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/notification" component={Notification} />
        <Route exact path="/notification/new" component={NotificationForm} />
        <Route
          exact
          path="/notification/edit/:annoucementid"
          component={NotificationEditForm}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
