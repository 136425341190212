export const API_URL =
  process.env.API_URL || 'https://premierfood-order-dev.appicidea.com/';

export const TABLE_PAGE_SIZE = 50;

export const TOAST_TIME = 5000;

export const IMAGE_URL = process.env.IMAGE_URL || '';

export const UNIT = [
  { value: '罐', label: '罐' },
  { value: '支', label: '支' },
  { value: '件', label: '件' },
  { value: '包', label: '包' },
  { value: '本', label: '本' },
  { value: '把', label: '把' },
  { value: '卷', label: '卷' },
  { value: '枝', label: '枝' },
  { value: '杯', label: '杯' },
  { value: '架', label: '架' },
  { value: '個', label: '個' },
  { value: '套', label: '套' },
  { value: '隻', label: '隻' },
  { value: '張', label: '張' },
  { value: '條', label: '條' },
  { value: '盒', label: '盒' },
  { value: '單', label: '單' },
  { value: '部', label: '部' },
  { value: '塊', label: '塊' },
  { value: '碗', label: '碗' },
  { value: '對', label: '對' },
  { value: '餅', label: '餅' },
  { value: '磅', label: '磅' },
  { value: '樽', label: '樽' },
];

export const PRODUCT_UNIT = [
  { value: '公斤', label: '公斤' },
  { value: '斤', label: '斤' },
  { value: '兩', label: '兩' },
  { value: '錢', label: '錢' },
  { value: '分', label: '分' },
  { value: '磅', label: '磅' },
];

export const PRODUCT_VALIDATE_OPTION = {
  abortEarly: false,
  recursive: true,
};

export const STAFF_TYPE = [
  { value: 'staff', label: '員工' },
  { value: 'manager', label: '分店經理' },
  { value: 'admin', label: '管理員(可使用管理員功能)' },
];

export const SHOP_JOYCE = [
  '02',
  '04',
  '09',
  '11',
  '15',
  '16',
  '17',
  '18',
  '19',
  '27',
  '29',
  '33',
  '37',
  '38',
];

export const SHOP_MICHELLE = [
  '03',
  '05',
  '08',
  '12',
  '13',
  '21',
  '26',
  '28',
  '31',
  '34',
  '36',
  '40',
];

export const SHOP_YUK = [
  '06',
  '07',
  '10',
  '14',
  '20',
  '22',
  '23',
  '24',
  '25',
  '30',
  '32',
  '35',
  '39',
];

export const STORE_OPTIONS = [
  { value: 'all', label: '搜尋' },
  { value: 'shop', label: '主倉店鋪' },
  { value: 'warehouse', label: '理想倉' },
  { value: 'manager', label: '經理' },
];
