/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Order from './Order/Loadable';
import OrderForm from './OrderForm/Loadable';
import OrderEditForm from './OrderEditForm/Loadable';

function index() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/Order" component={Order} />
        <Route exact path="/Order/new" component={OrderForm} />
        <Route exact path="/Order/edit/:orderid" component={OrderEditForm} />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
