import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import MenuItemButton from './MenuItemButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    margin: '0px 0px',
  },
  menuItem: {
    width: '100%',
    padding: '8px 16px',
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  aciveLinks: {
    borderRight: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(214, 79, 79, 0.1)',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'cener',
    justifyContent: 'flex-start',
  },
}));

function MenuItems({ setOpen, profile }) {
  const style = useStyles();
  const { type } = profile;
  return (
    <ul className={style.root}>
      <NavLink
        to="/notification"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <NotificationsIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="員工公告"
        />
      </NavLink>
      <NavLink
        to="/order"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <AssignmentIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="訂單管理"
        />
      </NavLink>
      <NavLink
        to="/pricerequest"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <LocalOfferIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="價錢牌申請"
        />
      </NavLink>
      {type && type === 'admin' ? (
        <NavLink
          to="/staff"
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <PeopleIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="員工管理"
          />
        </NavLink>
      ) : null}
    </ul>
  );
}

MenuItems.propTypes = {
  setOpen: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

export default MenuItems;
