import axios from '../service/axios';

// User
export const getUserList = filter => axios.get('/user/', { params: filter });

export const createNewUser = para => axios.post('/user', para);

export const changeOneUserProfile = (userid, values) =>
  axios.patch(`/user/${userid}`, values);

export const userLogin = para => axios.post('/user/login', para);

export const resetOneUserPassword = (userid, password) =>
  axios.post(`/user/${userid}/resetPassword`, password);

export const deleteOneUser = userid => axios.delete(`/user/${userid}`);

export const getUserProfile = () => axios.get('/user/profile');

export const getOneUserProfile = userid => axios.get(`/user/${userid}`);

export const userLogout = () => axios.post('/logout');

export const getManagerShopList = () => axios.get('/user/managerToShop');

// order
export const getOrderList = filter => axios.get('/order', { params: filter });

export const createNewOrder = para => axios.post('/order', para);

export const getOrderDetail = orderid => axios.get(`/order/${orderid}`);

export const updateOrderDetail = (orderid, para) =>
  axios.patch(`/order/${orderid}`, para);

export const approveByManager = orderid =>
  axios.post(`/order/${orderid}/managerApproved`);

export const approveByAdmin = orderid =>
  axios.post(`/order/${orderid}/adminApproved`);

export const orderAccepted = orderid =>
  axios.post(`/order/${orderid}/accepted`);

export const deleteOrder = orderid => axios.delete(`/order/${orderid}`);

export const batchUpdateOrder = array => {
  const params = {
    orderids: array,
  };
  return axios.post('/order/batchAccept', params);
};

// Product
export const getProductList = filter =>
  axios.get('/product', { params: filter });

export const getProductStat = filter =>
  axios.get('/product/stat', { params: filter });

export const getProductShortList = filter =>
  axios.get('/product/shortlist', { params: filter });

export const convertToLB = (productid, para) =>
  axios.post(`/product/${productid}/convertToLB`, para);

// Store
export const getStoreSellRecordList = filter =>
  axios.get('/store', { params: filter });

export const getStoreShortList = filter =>
  axios.get('/store/shortlist', { params: filter });

// stock
export const getStockList = filter => axios.get('/stock', { params: filter });

export const getStoreStat = filter =>
  axios.get('/store/stat', { params: filter });

export const getStockStat = filter =>
  axios.get('/stock/stat', { params: filter });

// category

export const getCategoryShortList = filter =>
  axios.get('/category/shortlist', { params: filter });

// shipment

export const getOutStoreList = filter =>
  axios.get('/outstore', { params: filter });

// annoucement

export const getNotiList = filter =>
  axios.get('/annoucement', { params: filter });

export const createNoti = para => axios.post('/annoucement', para);

export const deleteNoti = notiid => axios.delete(`/annoucement/${notiid}`);

export const updateNoti = (notiid, para) =>
  axios.patch(`/annoucement/${notiid}`, para);

export const getOneAnnouncement = notiid => axios.get(`/annoucement/${notiid}`);

// upload image

export const uploadImage = file => axios.post('/file/image', file);

// price request
export const getPriceRequestList = filter =>
  axios.get('/pricetagrequest', { params: filter });

export const createOnePriceRequest = params =>
  axios.post('/pricetagrequest', params);

export const getOnePriceRequest = priceTagRequestid =>
  axios.get(`/pricetagrequest/${priceTagRequestid}`);

export const updateOnePriceRequest = (priceTagRequestid, params) =>
  axios.patch(`/pricetagrequest/${priceTagRequestid}`, params);

export const completePriceRequest = priceTagRequestid =>
  axios.post(`/pricetagrequest/${priceTagRequestid}/completed`);

export const deleteOnePriceRequest = priceTagRequestid =>
  axios.delete(`/pricetagrequest/${priceTagRequestid}`);

export const batchCompleteRequest = params =>
  axios.post('/pricetagrequest/batchCompleted', params);

export const getDiscountProductShortList = filter =>
  axios.get('/discountproduct', { params: filter });

export const getUploadHistory = () =>
  axios.get('/discountproduct/upload/history', {
    params: { limit: 1, offset: 0 },
  });

export const batchDeletePriceRequest = priceTagRequestids => {
  axios.post('/pricetagrequest/batchDelete', { priceTagRequestids });
};

// upload excel

export const uploadDProduct = file =>
  axios.post('/discountproduct/upload', file);
