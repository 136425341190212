import React, { Fragment, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

// layout
// import Main from 'components/MainLayout/Main';

// pages
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import StaffPage from 'containers/StaffPage';
import OrderPage from 'containers/OrderPage';
import Login from 'containers/LoginPage/Loadable';
import DashboardPage from 'containers/DashboardPage';
import moment from 'moment';
import 'moment/locale/zh-hk';
import PriceRequestPage from '../PriceRequestPage/index';
import PrivateRoute from '../../helpers/PrivateRoute';
import { AuthContext } from '../../context/authContext';
import GlobalStyle from '../../global-styles';

import { TOAST_TIME } from './constants';

export default function App() {
  let existingTokens = null;
  const localToken = localStorage.getItem('tokens');
  if (localToken && localToken !== 'undefined') {
    existingTokens = localToken;
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = token => {
    localStorage.setItem('tokens', token);
    setAuthTokens(token);
  };
  moment.locale('zh-hk');
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Switch>
            <Route component={Login} exact path="/login" />
            <Route exact path="/">
              {authTokens ? (
                <Redirect to="/notification" />
              ) : (
                <Redirect to="login" />
              )}
            </Route>
            <PrivateRoute main path="/notification" component={DashboardPage} />
            <PrivateRoute main path="/order" component={OrderPage} />
            <PrivateRoute main path="/staff" component={StaffPage} />
            <PrivateRoute
              main
              path="/pricerequest"
              component={PriceRequestPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </MuiPickersUtilsProvider>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </Fragment>
    </AuthContext.Provider>
  );
}
