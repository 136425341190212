/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PriceRequestForm from './PriceRequestForm/Loadable';
import PriceRequestEditForm from './PriceRequestEditForm/Loadable';
import PriceRequest from './PriceRequest/Loadable';

function index() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/pricerequest" component={PriceRequest} />
        <Route exact path="/pricerequest/new" component={PriceRequestForm} />
        <Route
          exact
          path="/pricerequest/edit/:priceTagRequestid"
          component={PriceRequestEditForm}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
