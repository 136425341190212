import { createGlobalStyle } from 'styled-components';

const MAIN_COLOR = '#3f51b5';
const HOVER_COLOR = 'rgba(63, 81, 181, 0.3)';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  body.fontLoaded {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.5em;
  }

  #fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .DateRangePickerInput {
    // background-color: red;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid ${MAIN_COLOR};
  }
  .CalendarDay__selected {
    background: ${MAIN_COLOR};
    border: 1px double ${MAIN_COLOR};
    color: #fff;
  }
  .CalendarDay__selected {
    background: ${MAIN_COLOR};
    border: 1px double ${MAIN_COLOR};
    color: #fff;
  }
  .CalendarDay__selected:hover {
    background: ${MAIN_COLOR};
    border: 1px double ${MAIN_COLOR};
    color: #fff;
  }
  .CalendarDay__hovered_span {
    background: ${HOVER_COLOR};
    border: 1px double ${HOVER_COLOR};
    color: #fff;
  }
  .CalendarDay__hovered_span:hover {
    background: ${HOVER_COLOR};
    border: 1px double ${HOVER_COLOR};
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: ${HOVER_COLOR};
    border: 1px double ${HOVER_COLOR};
    color: #fff;
  }
  .CalendarDay__selected_span:hover {
    background: ${HOVER_COLOR};
    border: 1px double ${HOVER_COLOR};
    color: #fff;
  }
  .DateRangePicker_picker {
    z-index: 1000;
  }

  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left {
  }
  .Toastify__toast-container--top-center {
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
  }
  .Toastify__toast-container--bottom-center {
  }
  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast-body {
    font-size: 16px;
    color: #2e2e2e;
    font-weight: 500;
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
    background: #d64f4f !important;
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
`;

export default GlobalStyle;
